import React from "react";
import * as Icon from "react-feather";

const navigationConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Layout size={20} />,
    navLink: "/",
  },
  //! -----------Home Page---------
  {
    id: "homePage",
    title: "Home",
    type: "item",
    icon: <Icon.Home size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/home-page",
  },
  //!--------------static Pages list-------
  {
    id: "pages",
    title: "Pages",
    type: "item",
    icon: <Icon.Paperclip size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/pages",
  },
  //! -----------Static Pages---------
  {
    id: "staticPages",
    title: "Static Pages",
    type: "collapse",
    icon: <Icon.Anchor size={15} />,
    children: [
      {
        id: "About",
        title: "About Us",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/about",
      },
      {
        id: "privacyPolicy",
        title: "Privacy Policy",
        type: "item",
        icon: <Icon.List size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/privacy-policy",
      },
      {
        id: "TermsConditions",
        title: "Terms & Conditions",
        type: "item",
        icon: <Icon.List size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/terms-and-conditions",
      },
      {
        id: "Delivery",
        title: "Delivery",
        type: "item",
        icon: <Icon.List size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/delivery",
      },
      {
        id: "Sales_description ",
        title: "Sales Description",
        type: "item",
        icon: <Icon.List size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/SalesDisc",
      },
    ],
  },

  //!--------------Pages-------
  {
    id: "FAQS",
    title: "FAQ's",
    type: "item",
    icon: <Icon.HelpCircle size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/faqs",
  },
  {
    id: "ContactUsFormList",
    title: "Contact Us",
    type: "item",
    icon: <Icon.Phone size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/form-list",
  },
  {
    id: "SubscribersList",
    title: "Subscribers",
    type: "item",
    icon: <Icon.User size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/subscribers-list",
  },
  ,
  {
    id: "ClientReview",
    title: "Client Reviews",
    type: "item",
    icon: <Icon.Smile size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/client-review",
  },

  //!----------------Blogs--------

  {
    id: "blogs",
    title: "Blogs",
    type: "collapse",
    icon: <Icon.Book size={15} />,
    children: [
      {
        id: "blogList",
        title: "Blog",
        type: "item",
        icon: <Icon.AlertTriangle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/blog/list",
      },
      {
        id: "commentslist",
        title: "Comments",
        type: "item",
        icon: <Icon.Users size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/comments",
      },
    ],
  },
  //!----------------Product--------

  {
    id: "vouchers",
    title: "Vouchers",
    type: "item",
    icon: <Icon.Bookmark size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/voucher/list",
  },
  {
    id: "Product",
    title: "Product",
    type: "item",
    icon: <Icon.Archive size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/product/list",
  },
  //!----------------Country--------
  {
    id: "Country",
    title: "Countries",
    type: "item",
    icon: <Icon.Flag size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/country/list",
  },
  //!----------------Category --------
  {
    id: "category",
    title: "Category",
    type: "collapse",
    icon: <Icon.AlignJustify size={15} />,
    children: [
      {
        id: "Category",
        title: "Category",
        type: "item",
        icon: <Icon.AlignJustify size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/category/list",
      },
      {
        id: "SubCategory",
        title: "Sub Category",
        type: "item",
        icon: <Icon.AlignLeft size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/sub-category/list",
      },
      {
        id: "ChildSubCategory",
        title: "Child Sub Category",
        type: "item",
        icon: <Icon.AlignRight size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/child-sub-category/list",
      },
    ],
  },
  //!----------------Brands--------
  // {
  //   id: "brands",
  //   title: "Brands",
  //   type: "item",
  //   icon: <Icon.Award size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/brands/list",
  // },
  //!----------------Deals--------
  {
    id: "deals",
    title: "Deals",
    type: "item",
    icon: <Icon.CheckCircle size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/deals/list",
  },
  // !---------------- Best Selling Products--------
  {
    id: "BestSellingProducts",
    title: "Best Selling Products",
    type: "item",
    icon: <Icon.List size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/best-selling-products/list",
  },
  {
    id: "orderList",
    title: "Orders",
    type: "item",
    icon: <Icon.List size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/order-list",
  },

  // // !--------------Sales--------
  // {
  //   id: "saleslist",
  //   title: "Sales",
  //   type: "item",
  //   icon: <Icon.DollarSign size={12} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/sales/list",
  // },

  //!---------------Users------
  {
    id: "users",
    title: "Registered Users",
    type: "item",
    icon: <Icon.User size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/users-list",
  },

  //!---------------Reviews------
  {
    id: "reviews",
    title: "Reviews",
    type: "item",
    icon: <Icon.Star size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/reviews",
  },
  //!-------------Gallery--------
  {
    id: "gallery",
    title: "Gallery",
    type: "item",
    icon: <Icon.Image size={12} />,
    permissions: ["admin", "editor"],
    navLink: "/gallery",
  },
];

export default navigationConfig;

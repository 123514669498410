import React from "react";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Badge,
} from "reactstrap";
import * as Icon from "react-feather";
import { history } from "../../../history";
import { logoutAdmin } from "../../../redux/actions/auth/loginActions";
import { useDispatch } from "react-redux";
import { FaUserTie } from "react-icons/fa";
const handleNavigation = (e, path) => {
  e.preventDefault();
  history.push(path);
};

const UserDropdown = (props) => {
  const dispatch = useDispatch();

  // const handleLogout = () => {
  //   // dispatch(logoutAdmin);
  //   // logoutAdmin();
  //   localStorage.removeItem("auth_token");
  //   localStorage.removeItem("user_data");
  //   alert("Logout Successfully !");
  //   history.push(`/page/login`);
  // };
  return (
    <DropdownMenu right>
      <DropdownItem
        tag="a"
        href="#"
        onClick={(e) => handleNavigation(e, "/user-profile")}
      >
        <Icon.User size={14} className="mr-50" />
        <span className="align-middle">View Profile</span>
      </DropdownItem>
      <DropdownItem
        tag="a"
        href="#"
        onClick={(e) => {
          e.preventDefault();
          dispatch(logoutAdmin());
        }}
      >
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Log Out</span>
      </DropdownItem>
    </DropdownMenu>
  );
};

class NavbarUser extends React.PureComponent {
  render() {
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div
              className="user-nav d-sm-flex d-none"
              style={{ fontSize: "12px" }}
            >
              <span className="user-name text-bold-600">
                {console.log(this.props)}
                {this.props?.userData?.email}
              </span>
              <Badge color="success">
                <span className="user-status" style={{ fontSize: "10px" }}>
                  {this.props?.userData?.user_type}
                </span>
              </Badge>
            </div>
            <span data-tour="user">
              <FaUserTie size="20px" />
              {/* <img
                src={this.props.userImg}
                className="round"
                height="40"
                width="40"
                alt="avatar"
              /> */}
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
      </ul>
    );
  }
}

export default NavbarUser;
